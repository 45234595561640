
Object.defineProperty(exports, "__esModule", { value: true });

const {
  Decimal,
  objectEnumValues,
  makeStrictEnum,
  Public,
  getRuntime,
} = require('./runtime/index-browser.js')


const Prisma = {}

exports.Prisma = Prisma
exports.$Enums = {}

/**
 * Prisma Client JS version: 5.13.0
 * Query Engine version: b9a39a7ee606c28e3455d0fd60e78c3ba82b1a2b
 */
Prisma.prismaVersion = {
  client: "5.13.0",
  engine: "b9a39a7ee606c28e3455d0fd60e78c3ba82b1a2b"
}

Prisma.PrismaClientKnownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientKnownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)};
Prisma.PrismaClientUnknownRequestError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientUnknownRequestError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientRustPanicError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientRustPanicError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientInitializationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientInitializationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.PrismaClientValidationError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`PrismaClientValidationError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.NotFoundError = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`NotFoundError is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.Decimal = Decimal

/**
 * Re-export of sql-template-tag
 */
Prisma.sql = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`sqltag is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.empty = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`empty is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.join = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`join is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.raw = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`raw is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.validator = Public.validator

/**
* Extensions
*/
Prisma.getExtensionContext = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.getExtensionContext is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}
Prisma.defineExtension = () => {
  const runtimeName = getRuntime().prettyName;
  throw new Error(`Extensions.defineExtension is unable to run in this browser environment, or has been bundled for the browser (running in ${runtimeName}).
In case this error is unexpected for you, please report it in https://pris.ly/prisma-prisma-bug-report`,
)}

/**
 * Shorthand utilities for JSON filtering
 */
Prisma.DbNull = objectEnumValues.instances.DbNull
Prisma.JsonNull = objectEnumValues.instances.JsonNull
Prisma.AnyNull = objectEnumValues.instances.AnyNull

Prisma.NullTypes = {
  DbNull: objectEnumValues.classes.DbNull,
  JsonNull: objectEnumValues.classes.JsonNull,
  AnyNull: objectEnumValues.classes.AnyNull
}

/**
 * Enums
 */

exports.Prisma.TransactionIsolationLevel = makeStrictEnum({
  ReadUncommitted: 'ReadUncommitted',
  ReadCommitted: 'ReadCommitted',
  RepeatableRead: 'RepeatableRead',
  Serializable: 'Serializable'
});

exports.Prisma.AdminScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  type: 'type',
  name: 'name',
  password: 'password',
  email: 'email',
  emailVerified: 'emailVerified',
  image: 'image',
  system: 'system',
  mobile: 'mobile'
};

exports.Prisma.AdminAccountScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  userId: 'userId',
  type: 'type',
  provider: 'provider',
  providerAccountId: 'providerAccountId',
  refresh_token: 'refresh_token',
  access_token: 'access_token',
  expires_at: 'expires_at',
  token_type: 'token_type',
  scope: 'scope',
  id_token: 'id_token',
  session_state: 'session_state'
};

exports.Prisma.AdminRoleScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  description: 'description',
  permissions: 'permissions'
};

exports.Prisma.AdminRoleAdminScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  roleId: 'roleId',
  adminId: 'adminId'
};

exports.Prisma.UserTeamScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  description: 'description'
};

exports.Prisma.UserOnTeamScalarFieldEnum = {
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  uid: 'uid',
  teamId: 'teamId'
};

exports.Prisma.UserScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  mobile: 'mobile',
  email: 'email',
  emailVerified: 'emailVerified',
  image: 'image'
};

exports.Prisma.AccountScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  userId: 'userId',
  type: 'type',
  provider: 'provider',
  providerAccountId: 'providerAccountId',
  refresh_token: 'refresh_token',
  access_token: 'access_token',
  expires_at: 'expires_at',
  token_type: 'token_type',
  scope: 'scope',
  id_token: 'id_token',
  session_state: 'session_state'
};

exports.Prisma.ProfileScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  gender: 'gender',
  birthdate: 'birthdate',
  info: 'info',
  uid: 'uid'
};

exports.Prisma.AddressScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  default: 'default',
  title: 'title',
  phone: 'phone',
  roadAddress: 'roadAddress',
  jibunAddress: 'jibunAddress',
  postalCode: 'postalCode',
  x: 'x',
  y: 'y',
  profileId: 'profileId'
};

exports.Prisma.PhoneAuthScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  number: 'number',
  code: 'code',
  expireAt: 'expireAt'
};

exports.Prisma.PushTokenScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  token: 'token',
  uid: 'uid'
};

exports.Prisma.PrivacyScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  notification: 'notification',
  agreement: 'agreement',
  uid: 'uid'
};

exports.Prisma.VoiceScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  deletedAt: 'deletedAt',
  inUse: 'inUse',
  type: 'type',
  filepath: 'filepath',
  metadata: 'metadata',
  duration: 'duration',
  uid: 'uid',
  articleId: 'articleId',
  articlePackageId: 'articlePackageId',
  stt: 'stt',
  chunksList: 'chunksList',
  reportCount: 'reportCount'
};

exports.Prisma.VoiceResultScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  deletedAt: 'deletedAt',
  inUse: 'inUse',
  score: 'score',
  stt: 'stt',
  voiceId: 'voiceId'
};

exports.Prisma.ArticleScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  title: 'title',
  subtitle: 'subtitle',
  author: 'author',
  contents: 'contents',
  description: 'description',
  type: 'type',
  copyright: 'copyright',
  entireText: 'entireText',
  publishedAt: 'publishedAt',
  publish: 'publish',
  isVerse: 'isVerse',
  url: 'url',
  metadata: 'metadata',
  adminId: 'adminId'
};

exports.Prisma.ArticleCategoryScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  description: 'description',
  parentId: 'parentId'
};

exports.Prisma.ArticlePackageScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  description: 'description',
  author: 'author',
  publish: 'publish',
  price: 'price',
  adminId: 'adminId',
  categoryId: 'categoryId',
  metadata: 'metadata'
};

exports.Prisma.ArticleOnArticlePackageScalarFieldEnum = {
  order: 'order',
  articleId: 'articleId',
  articlePackageId: 'articlePackageId'
};

exports.Prisma.UserArticlePackageWishListScalarFieldEnum = {
  createdAt: 'createdAt',
  uid: 'uid',
  articlePackageId: 'articlePackageId'
};

exports.Prisma.BookScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  description: 'description',
  publish: 'publish',
  isbn: 'isbn',
  publisher: 'publisher',
  publishedAt: 'publishedAt',
  author: 'author',
  translator: 'translator',
  originalText: 'originalText',
  price: 'price',
  categoryId: 'categoryId'
};

exports.Prisma.ArticleOnBookScalarFieldEnum = {
  articleId: 'articleId',
  bookId: 'bookId'
};

exports.Prisma.ProductScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  title: 'title',
  description: 'description',
  images: 'images',
  keywords: 'keywords',
  metadata: 'metadata',
  stock: 'stock',
  isPhysical: 'isPhysical'
};

exports.Prisma.CategoryScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  title: 'title',
  description: 'description'
};

exports.Prisma.OrderItemScalarFieldEnum = {
  orderId: 'orderId',
  productId: 'productId',
  count: 'count'
};

exports.Prisma.OrderScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  number: 'number',
  status: 'status',
  trackingNumber: 'trackingNumber',
  isCompleted: 'isCompleted',
  addressId: 'addressId',
  uid: 'uid'
};

exports.Prisma.RefundScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  amount: 'amount',
  reason: 'reason',
  orderId: 'orderId'
};

exports.Prisma.EventScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  type: 'type',
  title: 'title',
  description: 'description',
  publish: 'publish',
  startedAt: 'startedAt',
  endedAt: 'endedAt'
};

exports.Prisma.EventProductScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  missionCount: 'missionCount',
  sendingAt: 'sendingAt',
  eventId: 'eventId',
  productId: 'productId'
};

exports.Prisma.UserNoticeScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  publish: 'publish',
  subject: 'subject',
  content: 'content',
  filepath: 'filepath',
  adminId: 'adminId'
};

exports.Prisma.ReadingAdmirationScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  publish: 'publish',
  subject: 'subject',
  content: 'content',
  filepath: 'filepath',
  videoUrl: 'videoUrl',
  adminId: 'adminId'
};

exports.Prisma.AttendanceScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  userId: 'userId',
  info: 'info'
};

exports.Prisma.AttendanceStatusScalarFieldEnum = {
  id: 'id',
  serialDays: 'serialDays',
  maxSerialDays: 'maxSerialDays',
  userId: 'userId'
};

exports.Prisma.CustomerServiceScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  title: 'title',
  content: 'content',
  email: 'email',
  mobile: 'mobile',
  status: 'status',
  uid: 'uid',
  categoryId: 'categoryId'
};

exports.Prisma.CustomerServiceCommentScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  content: 'content',
  customerServiceId: 'customerServiceId',
  adminId: 'adminId'
};

exports.Prisma.CustomerServiceCategoryScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  title: 'title',
  description: 'description'
};

exports.Prisma.OrganizationScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  name: 'name',
  description: 'description',
  info: 'info',
  parentId: 'parentId'
};

exports.Prisma.UserOrganizationScalarFieldEnum = {
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  uid: 'uid',
  organizationId: 'organizationId'
};

exports.Prisma.AdminOrganizationScalarFieldEnum = {
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  adminId: 'adminId',
  organizationId: 'organizationId'
};

exports.Prisma.ArticlePackageTagScalarFieldEnum = {
  id: 'id',
  text: 'text'
};

exports.Prisma.ArticlePackageOnArticlePackageTagScalarFieldEnum = {
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  articlePackageId: 'articlePackageId',
  articlePackageTagId: 'articlePackageTagId'
};

exports.Prisma.BookTagScalarFieldEnum = {
  id: 'id',
  text: 'text'
};

exports.Prisma.BookOnBookTagScalarFieldEnum = {
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  bookId: 'bookId',
  bookTagId: 'bookTagId'
};

exports.Prisma.ArticlePackageMediaScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  fileName: 'fileName',
  filePath: 'filePath',
  mimeType: 'mimeType'
};

exports.Prisma.ArticlePackageOnArticlePackageMediaScalarFieldEnum = {
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  articlePackageId: 'articlePackageId',
  articlePackageMediaId: 'articlePackageMediaId'
};

exports.Prisma.BookMediaScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  fileName: 'fileName',
  filePath: 'filePath',
  mimeType: 'mimeType'
};

exports.Prisma.BookOnBookMediaScalarFieldEnum = {
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  bookId: 'bookId',
  bookMediaId: 'bookMediaId'
};

exports.Prisma.UserTrophyScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  info: 'info',
  type: 'type',
  userId: 'userId'
};

exports.Prisma.SendMessageScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  from: 'from',
  title: 'title',
  text: 'text',
  data: 'data',
  type: 'type',
  schedule: 'schedule',
  sent_at: 'sent_at',
  read: 'read',
  recv: 'recv'
};

exports.Prisma.ApnCountScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  count: 'count',
  uid: 'uid'
};

exports.Prisma.UserFaqScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  publish: 'publish',
  question: 'question',
  answer: 'answer',
  filepath: 'filepath',
  adminId: 'adminId'
};

exports.Prisma.ReadingLevelMessageScalarFieldEnum = {
  id: 'id',
  createdAt: 'createdAt',
  updatedAt: 'updatedAt',
  level: 'level',
  totalDuration: 'totalDuration',
  message: 'message'
};

exports.Prisma.SortOrder = {
  asc: 'asc',
  desc: 'desc'
};

exports.Prisma.NullableJsonNullValueInput = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull
};

exports.Prisma.QueryMode = {
  default: 'default',
  insensitive: 'insensitive'
};

exports.Prisma.NullsOrder = {
  first: 'first',
  last: 'last'
};

exports.Prisma.JsonNullValueFilter = {
  DbNull: Prisma.DbNull,
  JsonNull: Prisma.JsonNull,
  AnyNull: Prisma.AnyNull
};
exports.AdminType = exports.$Enums.AdminType = {
  SuperAdmin: 'SuperAdmin',
  Admin: 'Admin',
  Provider: 'Provider'
};

exports.AdminPermission = exports.$Enums.AdminPermission = {
  USER_READER: 'USER_READER',
  USER_WRITER: 'USER_WRITER',
  NOTI_PUSH_READER: 'NOTI_PUSH_READER',
  NOTI_PUSH_WRITER: 'NOTI_PUSH_WRITER',
  NOTI_SMS_READER: 'NOTI_SMS_READER',
  NOTI_SMS_WRITER: 'NOTI_SMS_WRITER',
  NOTI_LOG_READER: 'NOTI_LOG_READER',
  NOTI_USER_NOTICE_READER: 'NOTI_USER_NOTICE_READER',
  NOTI_USER_NOTICE_WRITER: 'NOTI_USER_NOTICE_WRITER',
  SETTING_ADMIN_READER: 'SETTING_ADMIN_READER',
  SETTING_ADMIN_WRITER: 'SETTING_ADMIN_WRITER',
  SETTING_ADMIN_ROLE_READER: 'SETTING_ADMIN_ROLE_READER',
  SETTING_ADMIN_ROLE_WRITER: 'SETTING_ADMIN_ROLE_WRITER',
  READING_LOG_READER: 'READING_LOG_READER',
  CONTENTS_READING_EFFECT_READER: 'CONTENTS_READING_EFFECT_READER',
  CONTENTS_READING_EFFECT_WRITER: 'CONTENTS_READING_EFFECT_WRITER',
  SUPPORT_CS_EMAIL_READER: 'SUPPORT_CS_EMAIL_READER',
  SUPPORT_CS_EMAIL_WRITER: 'SUPPORT_CS_EMAIL_WRITER'
};

exports.GenderType = exports.$Enums.GenderType = {
  MALE: 'MALE',
  FEMALE: 'FEMALE',
  OTHER: 'OTHER',
  UNKNOWN: 'UNKNOWN'
};

exports.VoiceType = exports.$Enums.VoiceType = {
  VOICE_MY_BOOK: 'VOICE_MY_BOOK',
  VOICE_LIFE_QUOTES: 'VOICE_LIFE_QUOTES'
};

exports.ArticleType = exports.$Enums.ArticleType = {
  BOOK: 'BOOK',
  URL: 'URL',
  CREATIVE_WRITING: 'CREATIVE_WRITING'
};

exports.OrderStatusEnum = exports.$Enums.OrderStatusEnum = {
  Processing: 'Processing',
  Shipped: 'Shipped',
  Delivered: 'Delivered',
  ReturnProcessing: 'ReturnProcessing',
  ReturnCompleted: 'ReturnCompleted',
  Cancelled: 'Cancelled',
  RefundProcessing: 'RefundProcessing',
  RefundCompleted: 'RefundCompleted',
  Denied: 'Denied'
};

exports.EventType = exports.$Enums.EventType = {
  Monthly: 'Monthly',
  Malaton: 'Malaton'
};

exports.UserTrophyType = exports.$Enums.UserTrophyType = {
  ATTENDANCE: 'ATTENDANCE',
  READING_MISSION: 'READING_MISSION',
  READING_PACKAGE: 'READING_PACKAGE',
  READING_DURATION: 'READING_DURATION'
};

exports.SendMessageType = exports.$Enums.SendMessageType = {
  PUSH: 'PUSH',
  SMS: 'SMS',
  ALIM_TALK: 'ALIM_TALK'
};

exports.Prisma.ModelName = {
  Admin: 'Admin',
  AdminAccount: 'AdminAccount',
  AdminRole: 'AdminRole',
  AdminRoleAdmin: 'AdminRoleAdmin',
  UserTeam: 'UserTeam',
  UserOnTeam: 'UserOnTeam',
  User: 'User',
  Account: 'Account',
  Profile: 'Profile',
  Address: 'Address',
  PhoneAuth: 'PhoneAuth',
  PushToken: 'PushToken',
  Privacy: 'Privacy',
  Voice: 'Voice',
  VoiceResult: 'VoiceResult',
  Article: 'Article',
  ArticleCategory: 'ArticleCategory',
  ArticlePackage: 'ArticlePackage',
  ArticleOnArticlePackage: 'ArticleOnArticlePackage',
  UserArticlePackageWishList: 'UserArticlePackageWishList',
  Book: 'Book',
  ArticleOnBook: 'ArticleOnBook',
  Product: 'Product',
  Category: 'Category',
  OrderItem: 'OrderItem',
  Order: 'Order',
  Refund: 'Refund',
  Event: 'Event',
  EventProduct: 'EventProduct',
  UserNotice: 'UserNotice',
  ReadingAdmiration: 'ReadingAdmiration',
  Attendance: 'Attendance',
  AttendanceStatus: 'AttendanceStatus',
  CustomerService: 'CustomerService',
  CustomerServiceComment: 'CustomerServiceComment',
  CustomerServiceCategory: 'CustomerServiceCategory',
  Organization: 'Organization',
  UserOrganization: 'UserOrganization',
  AdminOrganization: 'AdminOrganization',
  ArticlePackageTag: 'ArticlePackageTag',
  ArticlePackageOnArticlePackageTag: 'ArticlePackageOnArticlePackageTag',
  BookTag: 'BookTag',
  BookOnBookTag: 'BookOnBookTag',
  ArticlePackageMedia: 'ArticlePackageMedia',
  ArticlePackageOnArticlePackageMedia: 'ArticlePackageOnArticlePackageMedia',
  BookMedia: 'BookMedia',
  BookOnBookMedia: 'BookOnBookMedia',
  UserTrophy: 'UserTrophy',
  SendMessage: 'SendMessage',
  ApnCount: 'ApnCount',
  UserFaq: 'UserFaq',
  ReadingLevelMessage: 'ReadingLevelMessage'
};

/**
 * This is a stub Prisma Client that will error at runtime if called.
 */
class PrismaClient {
  constructor() {
    return new Proxy(this, {
      get(target, prop) {
        let message
        const runtime = getRuntime()
        if (runtime.isEdge) {
          message = `PrismaClient is not configured to run in ${runtime.prettyName}. In order to run Prisma Client on edge runtime, either:
- Use Prisma Accelerate: https://pris.ly/d/accelerate
- Use Driver Adapters: https://pris.ly/d/driver-adapters
`;
        } else {
          message = 'PrismaClient is unable to run in this browser environment, or has been bundled for the browser (running in `' + runtime.prettyName + '`).'
        }
        
        message += `
If this is unexpected, please open an issue: https://pris.ly/prisma-prisma-bug-report`

        throw new Error(message)
      }
    })
  }
}

exports.PrismaClient = PrismaClient

Object.assign(exports, Prisma)
